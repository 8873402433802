.box-table {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e7ebee;
  border-radius: 3px;
  margin-top: 16px;
  min-height: 100px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  &.without-border {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}